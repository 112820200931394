export const getLocations = async () => {
    const url = process.env.REACT_APP_FLEET_MANAGER_URL + process.env.REACT_APP_FLEET_MANAGER_ENDPOINT_COMPANY_LOCATIONS
    return await fetch(url, {method: 'GET'})
        .then(response => {
            return response.json();
        })
        .then(response => {
            let locations_json = {}
            for (let i = 0; i < response.length; i++) {
                locations_json[response[i].name] = {"id": response[i].id, "city": response[i].city}
            }
            return locations_json;
        });
}

export const getReefers = async () => {
    const url = process.env.REACT_APP_FLEET_MANAGER_URL + process.env.REACT_APP_FLEET_MANAGER_ENDPOINT_COMPANY_REEFERS
    return await fetch(url, {method: 'GET'})
        .then(response => {
            return response.json();
        })
        .then(response => {
            let reefers_json = {}
            console.log(response)
            for (let i = 0; i < response.length; i++) {
                let name = response[i].name ? response[i].name : response[i].serial_number;
                reefers_json[name] = {"id": response[i].id, "serial_number": response[i].serial_number}
            }
            return reefers_json;
        });
}

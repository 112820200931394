import './css/common.css'
import './css/Home.css'

import React from "react"
import { useNavigate } from "react-router-dom";

import Header from "./utils/Header";

const Home = () => {
    const navigate = useNavigate();

    const onLoadButtonClick = () => {
        navigate(process.env.REACT_APP_ROUTE_LOAD_CHAMBER_VIEW)
    }

    const onServiceModeButtonClick = () => {
        navigate(process.env.REACT_APP_ROUTE_SERVICE_MODE_VIEW)
    }

    const onReeferButtonClick = () => {
        navigate(process.env.REACT_APP_ROUTE_REEFER_VIEW)
    }

    return (
        <div>
            <Header />
            <div className="main-container">
                <div className="view-button-container">
                    <button className="button-container view-button"
                            type="button"
                            onClick={onLoadButtonClick}>Load Chamber
                    </button>
                    <button className="button-container view-button"
                            type="button"
                            onClick={onServiceModeButtonClick}>Service Mode
                    </button>
                    <button className="button-container view-button"
                            type="button"
                            onClick={onReeferButtonClick}>Reefer View
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Home
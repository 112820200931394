import 'react-dropdown/style.css'
import './css/ServiceModeView.css'
import './css/common.css'

import React, { useEffect, useRef, useState } from "react";
import Dropdown from "react-dropdown";
import useWebSocket from "react-use-websocket";
import toast, { Toaster } from 'react-hot-toast';
import { getLocations } from "./utils/utils";
import ServiceModeAccordionTable from "./ServiceModeAccordionTable";
import Header from "./utils/Header";

const ServiceModeView = () => {
    const [locations, setLocations] = useState({});
    const [systems, setSystems] = useState({});
    const [selectedLocation, setSelectedLocation] = useState("");
    const [accordionTable, setAccordionTable] = useState(null)
    const lastMessageTimestamp = useRef(Date.now());

    const socketUrl = process.env.REACT_APP_CONTROLLER_WEBSOCKET;
    const {
        sendMessage,
        sendJsonMessage,
        lastMessage,
        lastJsonMessage,
        readyState,
        getWebSocket,
    } = useWebSocket(socketUrl, {
        heartbeat: {
            message: 'ping',
            returnMessage: 'pong',
            timeout: 60000, // 1 minute, if no response is received, the connection will be closed
            interval: 25000, // every 25 seconds, a ping message will be sent
        },
        // Will attempt to reconnect on all close events, such as server shutting down
        shouldReconnect: (closeEvent) => true,
    });

    useEffect(() => {
        localStorage.removeItem("systems");
        getLocations().then(response => {
            setLocations(response);
            const storageLocation = localStorage.getItem("location");
            if (storageLocation) {
                handleSelectLocation(storageLocation);
            }
        });
        checkWSConnection(15000);
    }, []);

    useEffect(() => {
        if (Object.keys(locations).length === 1) {
            handleSelectLocation(Object.keys(locations)[0])
        }
    }, [locations]);

    useEffect(() => {
        const storedSystems = JSON.parse(localStorage.getItem("systems"));
        if (Object.keys(systems).length > 0) {
            setAccordionTable(
                <ServiceModeAccordionTable systems={systems} handleModeChange={handleModeChange}>
                </ServiceModeAccordionTable>
            );
        }
        else if (storedSystems && Object.keys(storedSystems).length > 0) {
            setAccordionTable(
                <ServiceModeAccordionTable systems={storedSystems} handleModeChange={handleModeChange}>
                </ServiceModeAccordionTable>
            );
        }
        else {
            setAccordionTable(null);
        }
    }, [systems]);

    useEffect(() => {
        if (selectedLocation) {
            if (lastJsonMessage.payload && lastJsonMessage.payload.message_type === "chamber_statuses") {
                const raw_payload = lastJsonMessage.payload.chamber_statuses;
                let chamberStatuses = {}
                for (let i = 0; i < raw_payload.length; i++) {
                    chamberStatuses[raw_payload[i].name] = {
                        "burn_rate": raw_payload[i].burn_rate,
                        "mode": raw_payload[i].service_program,
                        "pressure": raw_payload[i].pressure,
                        "leak_rate": raw_payload[i].leak_rate,
                        "active": raw_payload[i].active
                    }
                }
                let temp_systems = {...systems};
                if (temp_systems[lastJsonMessage.controller]) {
                    temp_systems[lastJsonMessage.controller]["chamber_statuses"] = chamberStatuses
                }
                setSystems(temp_systems)
            }
            if (lastJsonMessage.payload && lastJsonMessage.payload.message_type === "sensor_data") {
                const raw_payload = lastJsonMessage.payload.sensor_data;
                let rounded_temp = raw_payload.temperature;
                if (rounded_temp) {
                    rounded_temp = Math.round(raw_payload.temperature * 10) / 10;
                }
                let sensor_data = {
                    "temperature": rounded_temp,
                    "humidity": raw_payload.humidity,
                    "instantaneous_pressure": raw_payload.pressure
                }
                let temp_systems = {...systems};
                if (temp_systems[lastJsonMessage.controller]) {
                    temp_systems[lastJsonMessage.controller]["sensor_data"] = sensor_data
                }
                setSystems(temp_systems)
            }
            if (lastJsonMessage.payload
                && lastJsonMessage.payload.message_type === "status_message"
                && lastJsonMessage.payload.status_text) {
                const statusText = lastJsonMessage.payload.status_text;

                const rateDataRegEx = /rate: (\d)*.(\d)*/g;
                const rateDataArray = statusText.match(rateDataRegEx);
                let rate = null;
                if (rateDataArray) {
                    rate = rateDataArray[0].split(" ")[1];
                    rate = rate.replace("(", "").replace(")", "");  // Just in case the parentheses are still there
                }
                let rateData = {
                    "rate_data": rate
                }
                let temp_systems = {...systems};
                if (temp_systems[lastJsonMessage.controller]) {
                    temp_systems[lastJsonMessage.controller]["rate_data"] = rateData;
                }
                setSystems(temp_systems);
            }
            if (lastJsonMessage.type === "error" && lastJsonMessage.payload) {
                let message = lastJsonMessage.payload.split(" ");
                let erroredSystem = message[message.length - 1];
                let temp_systems = {...systems};
                if (temp_systems[erroredSystem]) {
                    temp_systems[erroredSystem]["error"] = true;
                }
                setSystems(temp_systems);
            }
            lastMessageTimestamp.current = Date.now();
        }
    }, [lastJsonMessage])

    const handleSelectLocation = async (selection) => {
        if (locations && locations[selection]) {
            let url = process.env.REACT_APP_FLEET_MANAGER_URL
                    + process.env.REACT_APP_FLEET_MANAGER_ENDPOINT_CONTROLLERS_LOCATION
                    + locations[selection].id
            await fetch(url, {method: 'GET'})
                .then(response => {
                    return response.json();
                })
                .then(async response => {
                    let systemsJson = {}
                    localStorage.setItem("location", selection);
                    setSelectedLocation(selection);

                    if (response.length === 0) {
                        toast.error("No systems at location");
                    } else {
                        for (let i = 0; i < response.length; i++) {
                            systemsJson[response[i].serial_no] = {
                                "id": response[i].id,
                                "name": response[i].name,
                                "location": response[i].location
                            }
                        }
                    }
                    await handleSystemSubscription(systemsJson);
                 });
        }
    }

    const formatSystemNameMessages = (systemsObject) => {
        // Format the names of the systems for multiplex consumption (generally use for subscribe/unsubscribe messages)
        let systemNameMsg = [];
        for (const key in systemsObject) {
            systemNameMsg.push({"name": key, "fake": false})
        }
        return systemNameMsg;
    }

    const handleSystemSubscription = async (newSystems) => {
        if (Object.keys(systems).length > 0) {
            let systemMsg = formatSystemNameMessages(systems);
            sendMessage(JSON.stringify({"action": "unsubscribe", "controllers": systemMsg}))
        }
        if (Object.keys(newSystems).length > 0) {
            let systemMsg = formatSystemNameMessages(newSystems);
            sendMessage(JSON.stringify({"action": "subscribe", "controllers": systemMsg}))
        }
        localStorage.setItem('systems', JSON.stringify(newSystems));
        await setSystems(newSystems);
    }

    const handleModeChange = (systemSerialNo, chamberName, mode, notify= true) => {
        let jsonMsg = {
            "action": "change_mode",
            "controller": systemSerialNo,
            "chamber": parseInt(chamberName),
            "mode": mode};
        sendMessage(JSON.stringify(jsonMsg));
        if (notify) {
            let toastMsg = "Changing mode on "
                + systemSerialNo
                + " chamber "
                + chamberName
                + " to "
                + mode
                + ". Please be patient."
            toast.success(toastMsg);
        }
    }

    const checkWSConnection = async (timeout) => {
        const storedSystems = JSON.parse(localStorage.getItem("systems"));
        if (((Date.now() - lastMessageTimestamp.current) > timeout) && storedSystems) {
            await handleSystemSubscription(storedSystems);
        }
        setTimeout(checkWSConnection.bind(null, timeout), timeout);
    }


    return (
        <div>
            <Header />
            <div className="leak-rate-container">
                <div className="selection-container">
                    <Dropdown
                            options={Object.keys(locations)}
                            onChange={(option) => {
                                handleSelectLocation(option.value);
                            }}
                            value={selectedLocation}
                            placeholder="Location"
                    />
                </div>
                <div className="accordion-table-container">
                    {accordionTable}
                </div>
                <Toaster />
            </div>
        </div>
    )

}

export default ServiceModeView;